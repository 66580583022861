import React, { useEffect, useState } from "react";
import Select from "react-select";
import { AddButton, IconButton } from "../generalComponents";
import { RiAddLine } from "react-icons/ri";

function CariSelect({
  options,
  onChange,
  defaultValue,
  onClickButton,
  addOperation,
}) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  // react-select'in onChange olayı
  const handleCariChange = (selectedOption) => {
    defaultValue = undefined;
    setSelectedOption(selectedOption);
    onChange(selectedOption); // Seçilen cari değeri üst bileşene iletmek için callback'i çağır
  };

  useEffect(() => {
    //setSelectedOption(defaultValue);
  }, [options, defaultValue]);

  // react-select için seçeneklerin formatını hazırlama
  const cariOptions = options.map((cari) => ({
    value: cari.CariId,
    label: cari.CariUnvan,
  }));

  if (defaultValue != undefined) {
    defaultValue = cariOptions.find((x) => x.value == defaultValue.CariId);
  }

  const handleAddButtonClick = (e) => {
    e.preventDefault(); // Form gönderme davranışını durdur
    onClickButton(); // IconButtona tıklandığında çağrılacak işlevi çağır
  };

  const normalizeString = (str) => {
    const turkishMap = {
      ç: "c",
      Ç: "C",
      ğ: "g",
      Ğ: "G",
      ı: "i",
      I: "I",
      i: "i",
      İ: "I",
      ö: "o",
      Ö: "O",
      ş: "s",
      Ş: "S",
      ü: "u",
      Ü: "U",
    };
    return str
      .split("")
      .map((char) => turkishMap[char] || char)
      .join("")
      .toLowerCase();
  };

  const customFilterOption = (option, inputValue) => {
    const normalizedLabel = normalizeString(option.label);
    const normalizedInput = normalizeString(inputValue);
    return normalizedLabel.includes(normalizedInput);
  };

  return (
    <>
      {addOperation == false ? (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleCariChange}
            options={cariOptions}
            placeholder="Cari Seçin!"
            defaultValue={defaultValue != undefined && defaultValue}
            filterOption={customFilterOption}
          />
          <IconButton
            Icon={RiAddLine}
            bgColor={"primary-brand-color"}
            iconColor={"white-color"}
            onPress={handleAddButtonClick}
          />
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleCariChange}
            options={cariOptions}
            placeholder="Cari Seçin..."
            value={defaultValue !== undefined ? defaultValue : selectedOption}
            filterOption={customFilterOption}
          />
          <IconButton
            Icon={RiAddLine}
            bgColor={"primary-brand-color"}
            iconColor={"white-color"}
            onPress={handleAddButtonClick}
          />
        </div>
      )}
    </>
  );
}

export default CariSelect;
