import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { addIslemDurumSchema } from "services/helpers/validations";
import { useFormik } from "formik";
import { updateIslemDurum } from "services/api/yonetimApi/api";

const UpdateIslemDurumPopup = ({
  isOpen,
  onClose,
  buttonPosition,
  currentIslemDurum,
}) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDurum, setIsDurum] = useState(currentIslemDurum.durum); // True/False durumunu tutan state

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        IslemDurumAdi: currentIslemDurum.IslemAdi,
        IslemDurumAciklama: currentIslemDurum.IslemAciklama,
      },
      onSubmit: async (values, bag) => {
        bag.resetForm();

        const islemDurumAdiSubmit = values.IslemDurumAdi;
        const IslemDurumAciklamaSubmit = values.IslemDurumAciklama;
        updateIslemDurumProcess(islemDurumAdiSubmit, IslemDurumAciklamaSubmit);
      },
      validationSchema: addIslemDurumSchema,
    });

  useEffect(() => {
    if (currentIslemDurum.Durum !== undefined) {
      setIsDurum(currentIslemDurum.Durum);
    }
  }, [currentIslemDurum]);

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const updateIslemDurumProcess = async (islemDurumAdi, islemDurumAciklama) => {
    try {
      const updateIslemDurumApi = await updateIslemDurum(
        currentIslemDurum.IslemDurumId,
        islemDurumAdi,
        islemDurumAciklama,
        isDurum // true/false durumunu API'ye gönder
      );
      if (updateIslemDurumApi.succes) {
        console.log(
          "UpdateIslemDurumPopup -> updateIslemDurumProcess -> Islem Durum Güncellendi: ",
          updateIslemDurumApi.basicAciklama
        );
        onClose();
      } else {
        console.log(
          "UpdateIslemDurumPopup -> updateIslemDurumProcess -> Islem Durum Güncellenirken Hata: ",
          updateIslemDurumApi.basicAciklama
        );
      }
    } catch (error) {
      console.log(
        "UpdateIslemDurumPopup -> updateIslemDurumProcess -> Error: ",
        error
      );
    }
  };

  return (
    isOpen && (
      <div
        className="absolute bg-white-color border-2 shadow-lg rounded p-4 flex flex-col"
        style={{ top: buttonPosition.y + 15, left: buttonPosition.x - 200 }}
      >
        <div className="flex justify-between">
          <h3 className="text-primary-brand-color font-serif font-bold text-lg">
            İşlem Durum Ekle
          </h3>
          <button onClick={onClose} className="focus:outline-none">
            <FaTimes className="text-primary-brand-color" />
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="IslemDurumAdi"
            >
              İşlem Durum Adı
            </label>
            <input
              type="text"
              id="IslemDurumAdi"
              name="IslemDurumAdi"
              value={values.IslemDurumAdi}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.IslemDurumAdi && touched.IslemDurumAdi && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.IslemDurumAdi}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="IslemDurumAciklama"
            >
              İşlem Durum Açıklama
            </label>
            <input
              type="text"
              id="IslemDurumAciklama"
              name="IslemDurumAciklama"
              value={values.IslemDurumAciklama}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.IslemDurumAciklama && touched.IslemDurumAciklama && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.IslemDurumAciklama}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Durum">
              Durum
            </label>
            <div>
              <label style={{ marginRight: "10px" }}>
                <input
                  type="radio"
                  name="Durum"
                  value="true"
                  checked={isDurum === true}
                  onChange={() => setIsDurum(true)}
                />{" "}
                Aktif
              </label>
              <label>
                <input
                  type="radio"
                  name="Durum"
                  value="false"
                  checked={isDurum === false}
                  onChange={() => setIsDurum(false)}
                />{" "}
                Pasif
              </label>
            </div>
          </div>

          <button
            type="submit"
            className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
              isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
            }`}
            disabled={!isFormSubmitted}
          >
            Kaydet
          </button>
        </form>
      </div>
    )
  );
};

export default UpdateIslemDurumPopup;
