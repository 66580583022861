import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { FaCamera } from "react-icons/fa";
import { islemDurumData } from "data/data";
import { AiTwotoneDelete } from "react-icons/ai";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AddButton, IconButton, ConfirmModal } from "../generalComponents";
import { musteriTipData } from "data/data";
import { addCari, getCari, getSube } from "services/api/cariApi/api";
import { useFormik } from "formik";
import CariSelect from "./CariSelect";
import SubeSelect from "./SubeSelect";
import IslemDurumSelect from "./IslemDurumSelect";
import { getCihaz } from "services/api/cihazApi/api";
import CihazSelect from "./CihazSelect";
import { getKullanici } from "services/api/kullaniciApi/api";
import KullaniciSelect from "./KullaniciSelect";
import AddCihazPopup from "./AddCihazPopup";
import TabItemCihaz from "./TabItemCihaz";
import TabItemGecmisCihaz from "./TabItemGecmisCihaz";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import { tr } from "date-fns/locale/tr";
import {
  addServis,
  aktifServisCihaz,
  deleteServis,
  deleteServisBelge,
  deleteServisCihaz,
  getServisById,
  updateServis,
} from "services/api/servisApi/api";
import PopupAddCari from "../cariComponents/PopupAddCari";
import PopupAddSube from "../subelerComponents/PopupAddSube";
import UpdateCihazPopup from "./UpdateCihazPopup";
registerLocale("tr", tr);

function UpdateServisPopup({ onClose, currentServis, onUpdate }) {
  const [cariList, setCariList] = useState([]);
  const [subeList, setSubeList] = useState([]);
  const [cihazList, setCihazList] = useState([]);
  const [kullaniciList, setKullaniciList] = useState([]);
  const [ilcelerList, setIlcelerList] = useState([]);
  const [selectedIslemDurumId, setSelectedIslemDurumId] = useState(
    currentServis.IslemDurum
  );
  const [selectedCariId, setSelectedCariId] = useState(currentServis.CariId);
  const [selectedCari, setSelectedCari] = useState(currentServis.Cari);
  const [selectedSubeId, setSelectedSubeId] = useState(currentServis.SubeId);
  const [selectedCihazId, setSelectedCihazId] = useState("");
  const [selectedKullaniciId, setSelectedKullaniciId] = useState(
    currentServis.TeknisyenId
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [showAddCihazPopup, setShowAddCihazPopup] = useState(false);
  const [gelenCihazList, setGelenCihazList] = useState([]);
  const [currentCihazList, setCurrentCihazList] = useState([]);
  const [gecmisCihazlarList, setGecmisCihazlarList] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date(currentServis.Tarih)
  );
  const [addCariShowPopup, setAddCariShowPopup] = useState(false);
  const [addSubeShowPopup, setAddSubeShowPopup] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showUpdateCihazPopup, setShowUpdateCihazPopup] = useState(false);
  const [defaultCihaz, setDefaultCihaz] = useState(null);
  const [defaultServis, setDefaultServis] = useState(null);
  const [defaultBelgeler, setDefaultBelgeler] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);

  useEffect(() => {
    getServisByIdProcess(currentServis.ServisId);
    console.log("current Servis = ", currentServis.Cari);
    getCarilerProcess();
    getCihazlarProcess();
    getKullaniciProcess();
  }, [showUpdateCihazPopup]);

  const handleCariChange = (cari) => {
    setSelectedCariId(cari.value);
    setSelectedCari(cari);
    console.log(cari.value);
    getSubelerProcess(cari.value);
  };

  const handleSilClick = () => {
    setShowConfirmModal(true);
  };

  const handleServisCihazSilClick = async (cihaz) => {
    console.log(cihaz);
    try {
      const servisCihazSilApi = await deleteServisCihaz(
        cihaz.ServisId,
        cihaz.ServisCihazId
      );
      if (servisCihazSilApi.succes) {
        console.log(
          "UpdateServisPopup -> handleServisCihazSilClick -> Cihaz Başarılı Şekilde Silindi",
          servisCihazSilApi.basicAciklama
        );
        getServisByIdProcess(currentServis.ServisId);
      } else {
        console.log(
          "UpdateServisPopup -> handleServisCihazSilClick -> Servis Cihaz Silinirken Hata: ",
          servisCihazSilApi.basicAciklama
        );
      }
    } catch (err) {
      console.log(
        "UpdateServisPopup -> handleServisCihazSilClick -> Error: ",
        err
      );
    }
  };

  const handleServisCihazAktifClick = async (cihaz) => {
    console.log(cihaz);
    try {
      const servisCihazAktifApi = await aktifServisCihaz(
        cihaz.ServisId,
        cihaz.ServisCihazId
      );
      if (servisCihazAktifApi.succes) {
        console.log(
          "UpdateServisPopup -> handleServisCihazAktifClick -> Cihaz Başarılı Şekilde Aktifleştirildi",
          servisCihazAktifApi.basicAciklama
        );
        getServisByIdProcess(currentServis.ServisId);
      } else {
        console.log(
          "UpdateServisPopup -> handleServisCihazAktifClick -> Servis Cihaz Aktifleştiriken Hata: ",
          servisCihazAktifApi.basicAciklama
        );
      }
    } catch (err) {
      console.log(
        "UpdateServisPopup -> handleServisCihazSilClick -> Error: ",
        err
      );
    }
  };

  const handleGecmisCihazUpdate = async (cihaz) => {
    setDefaultCihaz(cihaz);
    //console.log("Gecmiş Cihaz: ", cihaz);
    setShowUpdateCihazPopup(!showUpdateCihazPopup);
  };

  const handleConfirmSil = () => {
    // Silme işlemi burada gerçekleştirilecek
    deleteServisProcess();
    console.log("Servis silindi");
  };

  const handleCancelSil = () => {
    setShowConfirmModal(false);
  };

  const handleSubeChange = (sube) => {
    setSelectedSubeId(sube.value);
  };

  const handleIslemDurumChange = (islemDurum) => {
    //console.log(islemDurum);
    setSelectedIslemDurumId(islemDurum.value);
  };

  const handleKullaniciChange = (kullanici) => {
    setSelectedKullaniciId(kullanici.value);
  };

  const handleAddCihaz = (gelenCihazList) => {
    console.log("Cihaz Ekleme:", gelenCihazList);
    const currentList = gelenCihazList[0];
    setCurrentCihazList([...currentCihazList, currentList]);
    //setGelenCihazList(currentList);
  };

  const handleLocalDeleteCihaz = (cihazId) => {
    console.log(cihazId);
    const updatedCihazlar = currentCihazList.filter(
      (cihaz) => cihaz.FirmaCihazId !== cihazId.FirmaCihazId
    );
    setCurrentCihazList(updatedCihazlar);
  };

  const handleAddCari = () => {
    setAddCariShowPopup(true);
  };

  const handleAddSube = () => {
    setAddSubeShowPopup(true);
  };

  const handleAddServis = () => {
    updateServisProcess(
      selectedCariId,
      selectedSubeId,
      selectedDate,
      selectedIslemDurumId,
      selectedKullaniciId,
      currentCihazList,
      gecmisCihazlarList
    );
  };

  const getSubelerProcess = async (cariId) => {
    try {
      const getSubelerApi = await getSube(cariId);
      if (getSubelerApi.success) {
        console.log(
          "AddServisPopup -> getSubelerProcess -> Subeler Başarılı Şekilde Çekildi ",
          getSubelerApi.basicAciklama
        );
        setSubeList(getSubelerApi.data);
      } else {
        console.log(
          "AddServisPopup -> getSubelerProcess -> Subeler Cekilirken Hata: ",
          getSubelerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("AddServisPopup -> getSubelerProcess -> Error:", error);
    }
  };

  const getCarilerProcess = async () => {
    try {
      const getCarilerApi = await getCari(
        true,
        1,
        1000,
        null,
        null,
        null,
        null
      );
      //console.log(getSehirlerApi.data);
      setCariList(getCarilerApi.data.data);
    } catch (error) {
      console.log("AddServisPopup -> getCarilerProcess -> Error: ", error);
    }
  };

  const getCihazlarProcess = async () => {
    try {
      const getCihazlarApi = await getCihaz();
      //console.log(getCihazlarApi.data);
      setCihazList(getCihazlarApi.data.data);
    } catch (error) {
      console.log("AddServisPopup -> getCihazlarProcess -> Error: ", error);
    }
  };

  const getKullaniciProcess = async () => {
    try {
      const getKullaniciApi = await getKullanici();
      setKullaniciList(getKullaniciApi.data);
    } catch (error) {
      console.log("AddServisPopup -> getKullaniciProcess -> Error: ", error);
    }
  };

  const deleteServisProcess = async () => {
    try {
      const deleteServisApi = await deleteServis(currentServis.ServisId);
      if (deleteServisApi.succes) {
        console.log(
          "UpdateServisPopup -> deleteServisProcess -> Servis Başarılı Şekilde Silindi",
          deleteServisApi.basicAciklama
        );
        onClose();
      } else {
        console.log(
          "UpdateServisPopup -> deleteServisProcess -> Servis Silinirken Hata",
          deleteServisApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("UpdateServisPopup -> deleteServisProcess -> Error: ", error);
    }
  };

  const updateServisProcess = async (
    CariId,
    SubeId,
    Tarih,
    IslemDurumId,
    TeknisyenId,
    CihazlarList,
    GecmisCihazlarList
  ) => {
    try {
      console.log("Yeni Eklenen Cihazlar:", CihazlarList);
      console.log("Geçmiş Cihazlar: ", GecmisCihazlarList);

      const formattedGecmisCihazlar = GecmisCihazlarList.map((gecmisCihaz) => ({
        ServisCihazId: gecmisCihaz.ServisCihazId,
        FirmaCihazAdi: gecmisCihaz.FirmaCihaz.CihazAdi,
        FirmaCihazId: gecmisCihaz.FirmaCihaz.FirmaCihazId,
        ServisIslemleri: gecmisCihaz.ServisIslemleri.map((islem) => ({
          Durum: true,
          ServisCihazIslemId: islem.ServisCihazIslemId,
          ServisCihazId: islem.ServisCihazId,
          ParcaId: islem.Parca ? islem.Parca.ParcaId : null,
          YapilanIslem: islem.YapilanIslem,
          Aciklama: islem.Aciklama,
          MusteriAciklamasi: islem.MusteriAciklamasi,
        })).filter((islem) => islem.ParcaId !== null), // null olanları filtrele
      }));

      console.log("Formatlanmış Geçmiş Cihazlar", formattedGecmisCihazlar);

      const combinedList = CihazlarList.concat(formattedGecmisCihazlar);
      console.log("Birleştirilmiş Liste:", combinedList);

      const updateServisApi = await updateServis(
        currentServis.ServisId,
        CariId,
        SubeId,
        Tarih,
        IslemDurumId,
        TeknisyenId,
        combinedList,
        selectedFiles
      );

      if (updateServisApi.success) {
        console.log(
          "UpdateServisPopup -> updateServisProcess -> Servis Başarılı Güncellendi: ",
          updateServisApi.basicAciklama
        );
        toast.success(updateServisApi.basicAciklama);
        onClose();
      } else {
        console.log(
          "UpdateServisPopup -> updateServisProcess -> Servis Güncellenirken Hata: ",
          updateServisApi.basicAciklama
        );
        toast.error(updateServisApi.basicAciklama);
      }
    } catch (error) {
      console.log("AddServisPopup -> addServisProcess -> Error: ", error);
    }
  };

  const getServisByIdProcess = async (servisId) => {
    try {
      const getServisByIdApi = await getServisById(servisId);
      if (getServisByIdApi.succes) {
        console.log(
          "UpdateServisPopup -> getServisByIdProcess -> servisler başarılı getirildi:",
          getServisByIdApi.basicAciklama
        );
        console.log("------->Deneme Deneme", getServisByIdApi.data);
        setDefaultServis(getServisByIdApi.data);
        setGecmisCihazlarList(getServisByIdApi.data.Cihazlar);
        //console.log("----->", getServisByIdApi.data);
        setDefaultBelgeler(getServisByIdApi.data.Belgeler);
      } else {
        console.log(
          "UpdateServisPopup -> getServisByIdProcess -> servisler getirilirken hata:",
          getServisByIdApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("UpdateServisPopup -> getServisById -> Error: ", error);
    }
  };

  const handleFileRemove = async (belgeId) => {
    console.log(belgeId);
    try {
      const handleFileApi = await deleteServisBelge(belgeId);
      if (handleFileApi.succes) {
        console.log(
          "UpdateServisPopup -> handleFileRemove -> Succes: true : ",
          handleFileApi.basicAciklama
        );
        getServisByIdProcess(currentServis.ServisId);
      } else {
        console.log(
          "UpdateServisPopup -> handleFileRemove -> Succes: false : ",
          handleFileApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("UpdateServisPopup -> handleFileRemove -> Errır: ", error);
    }
  };

  const handleLocalFileRemove = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const handleCameraButton = async () => {
    document.getElementById("fileInput").click();
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    console.log(event.target.files);
    setSelectedFiles(files);
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-5/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Servis Güncelle</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <form className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4">
          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Cari">
              Cari
            </label>
            {cariList.length != 0 && (
              <CariSelect
                defaultValue={currentServis.Cari}
                options={cariList}
                onChange={handleCariChange}
                onClickButton={handleAddCari}
                addOperation={false}
              />
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Sube">
              Lokasyon
            </label>
            <SubeSelect
              options={subeList}
              onChange={handleSubeChange}
              onClickButton={handleAddSube}
              defaultValue={currentServis.Sube}
              addOperation={false}
              selectedCariId={currentServis.CariId}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="IslemDurum"
            >
              İşlem Durum
            </label>
            <IslemDurumSelect
              options={islemDurumData}
              onChange={handleIslemDurumChange}
              addOperation={false}
              defaultValue={currentServis.IslemDurum}
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="kullanici"
            >
              Teknisyen
            </label>
            {kullaniciList.length != 0 && (
              <KullaniciSelect
                options={kullaniciList}
                onChange={handleKullaniciChange}
                addOperation={false}
                defaultValue={currentServis.Teknisyen}
              />
            )}
          </div>

          <div className="mb-4 flex flex-col">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="GarantiTarihi"
            >
              Tarih
            </label>
            <DatePicker
              className="w-full border-2 border-gray-color p-1 rounded-md"
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="dd/MM/yyyy" // Tarih formatını ayarla (isteğe bağlı)
              placeholderText="Tarih Seçin"
              locale="tr"
            />
          </div>

          <div className="mb-4 flex flex-col">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="GarantiTarihi"
            >
              Bu Servis İçin Dosya Yükle
            </label>
            <div className="flex w-full justify-start items-center">
              <IconButton
                onPress={handleCameraButton}
                bgColor="primary-brand-color"
                Icon={FaCamera}
                iconColor="white-color"
                textColor="white-color"
              />
              <input
                id="fileInput"
                type="file"
                accept="image/*,.pdf"
                multiple
                onChange={handleFileChange}
                style={{ display: "none" }}
              />
            </div>
          </div>
        </form>
        {/* <hr /> */}
        {/* <AddButton
          onPress={() => {
            setShowAddCihazPopup(true);
          }}
          bgColor={"primary-brand-color"}
          title={"Cihaz Ekle"}
          iconColor="white-color"
          textColor="white-color"
        /> */}
        {/* Cihaz Ekleme Popup'ı */}
        {showAddCihazPopup && (
          <AddCihazPopup
            onConfirm={handleAddCihaz}
            onCancel={() => setShowAddCihazPopup(false)}
            cariId={selectedCariId}
          />
        )}
        {/* <div className="mt-4">
          <hr />
          <h2 className=" text-xl font-semibold font-serif">
            Servise Yeni Eklenen Cihazlar
          </h2>

          {currentCihazList.map((gelenCihaz) => (
            <TabItemCihaz
              cihaz={gelenCihaz}
              addOperation={false}
              onDeleteClick={handleLocalDeleteCihaz}
            />
          ))}
        </div> */}
        <div className="mt-4">
          <hr />
          <h2 className=" text-xl font-semibold font-serif">
            Serviste Olan Cihazlar
          </h2>

          {gecmisCihazlarList.map((gelenCihaz) => (
            <TabItemGecmisCihaz
              cihaz={gelenCihaz}
              addOperation={false}
              cihazSilClick={handleServisCihazSilClick}
              cihazAktifClick={handleServisCihazAktifClick}
              cihazUpdateClick={handleGecmisCihazUpdate}
            />
          ))}

          <div className="mt-4">
            {selectedFiles.length > 0 && (
              <>
                <hr />
                <h3 className="text-xl font-semibold">
                  Servise Yeni Yüklenen Dosyalar
                </h3>
                <ul>
                  {selectedFiles.map((file, index) => (
                    <li
                      className=" justify-start items-center flex"
                      key={index}
                    >
                      {file.name}
                      <button
                        className="ml-4 text-red-color"
                        onClick={() => handleLocalFileRemove(index)}
                      >
                        Sil
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>

          <div className="mt-4">
            <hr />
            {defaultBelgeler.length > 0 && (
              <>
                <h3 className="text-xl font-semibold">
                  Serviste Olan Dosyalar
                </h3>
                <ul>
                  {defaultBelgeler.map((file, index) => (
                    <li
                      className=" justify-start items-center flex"
                      key={index}
                    >
                      {file.BelgeAdi}
                      <button
                        className="ml-4 text-red-color"
                        onClick={() => handleFileRemove(file.BelgeId)}
                      >
                        Sil
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>

          {showUpdateCihazPopup && (
            <UpdateCihazPopup
              onConfirm={() => onUpdate((prev) => !prev)}
              onCancel={() => setShowUpdateCihazPopup(false)}
              cihaz={defaultCihaz}
            />
          )}
        </div>
        <div className="flex justify-end items-center w-full">
          <IconButton
            onPress={handleSilClick}
            bgColor="red-color"
            Icon={AiTwotoneDelete}
            iconColor="white-color"
            textColor="white-color"
          />
          <AddButton
            onPress={handleAddServis}
            bgColor={"primary-brand-color"}
            title={"Servisİ Güncelle"}
            iconColor="white-color"
            textColor="white-color"
          />
        </div>
      </div>
      {addCariShowPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <PopupAddCari onClose={() => setAddCariShowPopup(false)} />
        </div>
      )}
      {addSubeShowPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <PopupAddSube
            onClose={() => setAddSubeShowPopup(false)}
            currentCari={selectedCari}
            isSection={false}
          />
        </div>
      )}
      {showConfirmModal && (
        <ConfirmModal
          message="Bu Servisi  Silmek İstediğinizden Emin misiniz?"
          onConfirm={handleConfirmSil}
          onCancel={handleCancelSil}
        />
      )}
    </div>
  );
}

export default UpdateServisPopup;
