import React from "react";
import { IoMdAdd } from "react-icons/io";
import { IoFilterSharp } from "react-icons/io5";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

function JustHeader({ onAddClick, title }) {
  return (
    <div className="w-full flex justify-between items-center bg-white-color p-1 border-b-2 border-primary-brand-color mb-2">
      <h1 className=" w-10/12 text-primary-brand-color text-2xl font-semibold font-serif">
        {title}
      </h1>
      <button
        className=" bg-secondary-brand-color text-white-color px-2 py-2 border-2 border-primary-brand-color  rounded-full text-xl"
        onClick={onAddClick}
      >
        <IoMdAdd />
      </button>
    </div>
  );
}

export default JustHeader;
