import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import { getIlceler, getSehirler } from "services/api/yonetimApi/api";
import { SehirSelect, IlceSelect, CariTipSelect } from "../generalComponents";
import { addSube } from "services/api/cariApi/api";
import { useFormik } from "formik";
import { addSubeSchema } from "services/helpers/validations";

function PopupAddSube({ onClose, currentCari, isSection }) {
  const [sehirlerList, setSehirlerList] = useState([]);
  const [ilcelerList, setIlcelerList] = useState([]);
  const [selectedMusteriTip, setSelectedMusteriTip] = useState("");
  const [selectedSehirId, setSelectedSehirId] = useState("");
  const [selectedIlceId, setSelectedIlceId] = useState("");
  // const [selectedTuzelDurum, setSelectedTuzelDurum] = useState(
  //   currentCari.CariOzelTuzel
  // );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    console.log(currentCari);
    getSehirlerProcess();
  }, []);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        SubeAdi: "",
        VergiDairesi: "",
        VergiNo: "",
        Adres: "",
        Telefon: "",
        Email: "",
        YetkiliAd: "",
        YetkiliGsm: "",
      },

      onSubmit: async (values, bag) => {
        bag.resetForm();
        const SubeAdiSubmit = values.SubeAdi;
        const VergiDairesiSubmit = values.VergiDairesi;
        const VergiNoSubmit = values.VergiNo;
        const AdresSubmit = values.Adres;
        const TelefonSubmit = values.Telefon;
        const EmailSubmit = values.Email;
        const YetkiliAdSubmit = values.YetkiliAd;
        const YetkiliGsmSubmit = values.YetkiliGsm;

        addSubeProcess(
          SubeAdiSubmit,
          VergiDairesiSubmit,
          VergiNoSubmit,
          AdresSubmit,
          TelefonSubmit,
          EmailSubmit,
          YetkiliAdSubmit,
          YetkiliGsmSubmit
        );
      },
      validationSchema: addSubeSchema,
    });

  useEffect(() => {
    if (Object.keys(errors).length === 0) {
      if (selectedSehirId && selectedIlceId) {
        setIsFormSubmitted(true);
      }
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const handleSehirChange = (sehir) => {
    setSelectedSehirId(sehir.value);

    getIlceProcess(sehir.value);
  };

  const handleIlceChange = (ilce) => {
    setSelectedIlceId(ilce.value);
  };

  const getIlceProcess = async (sehirId) => {
    try {
      const getIlcelerApi = await getIlceler(sehirId);

      setIlcelerList(getIlcelerApi.data);
    } catch (error) {
      console.log("PopupAddCari -> getIlcelerProcess -> Error: ", error);
    }
  };

  const getSehirlerProcess = async () => {
    try {
      const getSehirlerApi = await getSehirler();
      //console.log(getSehirlerApi.data);
      setSehirlerList(getSehirlerApi.data);
    } catch (error) {
      console.log("PopupAddCari -> getSehirlerProcess -> Error: ", error);
    }
  };

  const addSubeProcess = async (
    SubeAdiSubmit,
    VergiDairesiSubmit,
    VergiNoSubmit,
    AdresSubmit,
    TelefonSubmit,
    EmailSubmit,
    YetkiliAdSubmit,
    YetkiliGsmSubmit
  ) => {
    console.log(
      SubeAdiSubmit,
      VergiDairesiSubmit,
      VergiNoSubmit,
      AdresSubmit,
      TelefonSubmit,
      EmailSubmit,
      YetkiliAdSubmit,
      YetkiliGsmSubmit,
      selectedSehirId,
      selectedIlceId
    );
    try {
      const cariId = isSection ? currentCari.CariId : currentCari.value;

      const addSubeApi = await addSube(
        cariId,
        SubeAdiSubmit,
        VergiDairesiSubmit,
        VergiNoSubmit,
        selectedSehirId,
        selectedIlceId,
        AdresSubmit,
        TelefonSubmit,
        EmailSubmit,
        YetkiliAdSubmit,
        YetkiliGsmSubmit
      );
      if (addSubeApi.success) {
        console.log("Sube Eklendi");
        onClose();
      } else {
        console.log("Cari Eklenemedi");
      }
    } catch (error) {
      console.log("PopupAddSube -> addSubeProcess -> Error: ", error);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-4/6 border-2 border-primary-brand-color overflow-auto">
        <div className="flex flex-row justify-between items-center mb-3">
          <h2 className="text-2xl font-semibold">Yeni Lokasyon Ekle</h2>
          <button
            className=" text-xl text-primary-brand-color"
            onClick={onClose}
          >
            <IoMdClose />
          </button>
        </div>
        <hr />
        <form
          onSubmit={handleSubmit}
          className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4"
        >
          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="SubeAd"
            >
              Lokasyon Adı
            </label>
            <input
              type="text"
              id="SubeAdi"
              name="SubeAdi"
              value={values.SubeAdi}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.SubeAdi && touched.SubeAdi && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.SubeAdi}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="VergiDairesi"
            >
              Vergi Dairesi
            </label>
            <input
              type="text"
              id="VergiDairesi"
              name="VergiDairesi"
              value={values.VergiDairesi}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.VergiDairesi && touched.VergiDairesi && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.VergiDairesi}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="VergiNo"
            >
              Vergi No
            </label>
            <input
              type="text"
              id="VergiNo"
              name="VergiNo"
              value={values.VergiNo}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.VergiNo && touched.VergiNo && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.VergiNo}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="Telefon"
            >
              Telefon
            </label>
            <input
              type="text"
              id="Telefon"
              name="Telefon"
              value={values.Telefon}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.Telefon && touched.Telefon && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Telefon}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Email">
              E-mail
            </label>
            <input
              type="text"
              id="Email"
              name="Email"
              value={values.Email}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.Email && touched.Email && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Email}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="YetkiliAd"
            >
              Yetkili Ad
            </label>
            <input
              type="text"
              id="YetkiliAd"
              name="YetkiliAd"
              value={values.YetkiliAd}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliAd && touched.YetkiliAd && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliAd}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="YetkiliGsm"
            >
              Yetkili Telefon
            </label>
            <input
              type="text"
              id="YetkiliGsm"
              name="YetkiliGsm"
              value={values.YetkiliGsm}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.YetkiliGsm && touched.YetkiliGsm && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.YetkiliGsm}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Şehir">
              Şehir
            </label>
            <SehirSelect options={sehirlerList} onChange={handleSehirChange} />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="İlce">
              İlçe
            </label>
            <IlceSelect options={ilcelerList} onChange={handleIlceChange} />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-semibold mb-1" htmlFor="Adres">
              Adres
            </label>
            <textarea
              type="text"
              id="Adres"
              name="Adres"
              value={values.Adres}
              onBlur={handleBlur}
              onChange={handleChange}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
              rows={3}
            />
            {errors.Adres && touched.Adres && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.Adres}
                </p>
              </div>
            )}
          </div>

          <div className="md:col-span-2 col-span-1">
            <button
              type="submit"
              className={`bg-primary-brand-color text-white-color px-4 py-2 rounded-md ${
                isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
              }`}
              disabled={!isFormSubmitted}
            >
              Kaydet
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PopupAddSube;
