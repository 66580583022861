import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa"; // react-icons'dan FaTimes ikonunu içe aktarın
import { addIslemDurumSchema } from "services/helpers/validations";
import { useFormik } from "formik";
import { addCihazTip, addIslemDurum } from "services/api/yonetimApi/api";

const AddIslemDurumPopup = ({ isOpen, onClose, buttonPosition }) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        IslemDurumAdi: "",
        IslemDurumAciklama: "",
      },
      onSubmit: async (values, bag) => {
        bag.resetForm();

        const islemDurumAdiSubmit = values.IslemDurumAdi;
        const IslemDurumAciklamaSubmit = values.IslemDurumAciklama;
        addIslemDurumProcess(islemDurumAdiSubmit, IslemDurumAciklamaSubmit);
      },
      validationSchema: addIslemDurumSchema,
    });

  useEffect(() => {
    //getCariler();
    //console.log(options);
  }, []);

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      setIsFormSubmitted(true);
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const addIslemDurumProcess = async (islemDurumAdi, islemDurumAciklama) => {
    try {
      const addIslemDurumApi = await addIslemDurum(
        islemDurumAdi,
        islemDurumAciklama
      );
      if (addIslemDurumApi.succes) {
        console.log(
          "AddIslemDurumPopup -> addIslemDurumProcess -> Islem Durum Başarılı Bir Şekilde Eklendi: ",
          addIslemDurumApi.basicAciklama
        );
        onClose();
      } else {
        console.log(
          "AddIslemDurumPopup -> addIslemDurumProcess -> Islem Durum Eklenirken Hata: ",
          addIslemDurumApi.basicAciklama
        );
      }
    } catch (error) {
      console.log(
        "AddIslemDurumPopup -> addIslemDurumProcess -> Error: ",
        error
      );
    }
  };

  return (
    isOpen && (
      <div
        className="absolute bg-white-color border-2 shadow-lg rounded p-4 flex flex-col"
        style={{ top: buttonPosition.y + 15, left: buttonPosition.x - 200 }}
      >
        <div className="flex justify-between">
          <h3 className="text-primary-brand-color font-serif font-bold text-lg">
            İşlem Durum Ekle
          </h3>
          <button onClick={onClose} className="focus:outline-none">
            <FaTimes className="text-primary-brand-color" />{" "}
            {/* Kapatma butonu için FaTimes ikonunu kullanın */}
          </button>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="IslemDurumAdi"
            >
              İşlem Durum Adı
            </label>
            <input
              type="text"
              id="IslemDurumAdi"
              name="IslemDurumAdi"
              value={values.IslemDurumAdi}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.IslemDurumAdi && touched.IslemDurumAdi && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.IslemDurumAdi}
                </p>
              </div>
            )}
          </div>

          <div className="mb-4">
            <label
              className="block text-sm font-semibold mb-1"
              htmlFor="IslemDurumAciklama"
            >
              İşlem Durum Açıklama
            </label>
            <input
              type="text"
              id="IslemDurumAciklama"
              name="IslemDurumAciklama"
              value={values.IslemDurumAciklama}
              onChange={handleChange}
              onBlur={handleBlur}
              className="w-full border-2 border-gray-200 p-1 rounded-md"
            />
            {errors.IslemDurumAciklama && touched.IslemDurumAciklama && (
              <div>
                <p className="font-semibold text-xs text-red-color">
                  {errors.IslemDurumAciklama}
                </p>
              </div>
            )}
          </div>

          <button
            type="submit"
            className={`bg-green-color text-white-color px-4 py-2 rounded-md ${
              isFormSubmitted ? "" : "bg-opacity-50 cursor-not-allowed"
            }`}
            disabled={!isFormSubmitted}
          >
            Kaydet
          </button>
        </form>
      </div>
    )
  );
};

export default AddIslemDurumPopup;
