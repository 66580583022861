import React, { useEffect } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import CanonLogo from "./../../../assets/CanonLogo.png";

Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});

const RaporServisPdf = ({ servisList }) => {
  const formattedDate = new Date().toLocaleDateString("tr-TR");

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.header}>
            <Image src={CanonLogo} style={styles.logo} />
            <Text style={styles.titleMain}>Servis Raporu</Text>
            <Text style={styles.textDate}>
              <Text>Tarih:</Text> {formattedDate}
            </Text>
          </View>
          <Text style={styles.text}>Servis Kayıtları</Text>

          <View style={styles.bodyMain}>
            {servisList && servisList.length > 0 ? (
              servisList.map((servis, index) => (
                <View style={styles.deviceContainer} key={index}>
                  <View
                    style={{
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <View style={styles.deviceHeader}>
                      <Text style={styles.deviceTitle}>
                        Cari Unvan / Cihaz Adı:
                      </Text>
                      <Text style={styles.deviceValue}>
                        {servis.Cari.CariUnvan} /{" "}
                        {servis.Cihazlar[0].FirmaCihaz.CihazAdi}
                      </Text>
                    </View>
                    <View style={styles.dateHeader}>
                      <Text style={styles.deviceTitle}>Tarih:</Text>
                      <Text style={styles.deviceValue}>
                        {new Date(servis.Tarih).toLocaleDateString("tr-TR")}
                      </Text>
                    </View>
                  </View>

                  {servis.Cihazlar[0].ServisIslemleri &&
                  servis.Cihazlar[0].ServisIslemleri.length > 0 ? (
                    servis.Cihazlar[0].ServisIslemleri.map(
                      (islem, islemIndex) => (
                        <View style={styles.deviceRow} key={islemIndex}>
                          {islem.Parca != null ? (
                            <View style={styles.deviceColumn}>
                              <Text style={styles.label}>Değişen Parça</Text>
                              <Text style={styles.value}>
                                {islem.Parca.ParcaAdi != null
                                  ? islem.Parca.ParcaAdi
                                  : `${islem.Parca}`}
                              </Text>
                            </View>
                          ) : (
                            <View style={styles.deviceColumn}>
                              <Text style={styles.label}>Değişen Parça</Text>
                              <Text style={styles.value}>
                                Değişen Parça Yok
                              </Text>
                            </View>
                          )}

                          <View style={styles.deviceColumn}>
                            <Text style={styles.label}>Yapılan İşlem</Text>
                            <Text style={styles.value}>
                              {islem.YapilanIslem != null &&
                              islem.YapilanIslem != undefined
                                ? islem.YapilanIslem
                                : "İşlem Yapılmadı"}
                            </Text>
                          </View>

                          <View style={styles.deviceColumn}>
                            <Text style={styles.label}>Müşteri Açıklaması</Text>
                            <Text style={styles.value}>
                              {islem.MusteriAciklamasi != null &&
                              islem.MusteriAciklamasi != undefined
                                ? islem.MusteriAciklamasi
                                : "İşlem Yapılmadı"}
                            </Text>
                          </View>
                        </View>
                      )
                    )
                  ) : (
                    <Text style={styles.noData}>Servis işlemi yok</Text>
                  )}
                </View>
              ))
            ) : (
              <Text style={styles.noData}>Cihaz bilgisi yok</Text>
            )}
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default RaporServisPdf;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 10,
    backgroundColor: "white",
    fontFamily: "Ubuntu",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    backgroundColor: "white",
    borderColor: "#ddd",
    borderWidth: 1,
    borderRadius: 5,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: {
    width: 100,
    height: 50,
  },
  titleMain: {
    fontSize: 20,
    fontWeight: "normal",
    color: "black",
    //fontFamily: "Roboto Black",
  },
  textDate: {
    fontSize: 12,
    color: "black",
    //fontFamily: "Roboto Black",
  },
  text: {
    fontSize: 12,
    marginBottom: 5,
    color: "black",
    //fontFamily: "Roboto Black",
  },
  bodyMain: {
    marginTop: 10,
  },
  sectionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
  },
  deviceContainer: {
    marginBottom: 20,
    padding: 10,
    backgroundColor: "#ffffff",
    borderRadius: 5,
    borderWidth: 1,
    borderColor: "#ddd",
  },
  deviceHeader: {
    marginBottom: 10,
  },
  dateHeader: {
    marginBottom: 10,
    marginLeft: 4,
    flexDirection: "row",
  },
  deviceTitle: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 2,
    color: "#333",
  },
  deviceValue: {
    fontSize: 12,
    fontWeight: "normal",
    color: "#555",
  },
  deviceRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 10,
    padding: 10,
    backgroundColor: "#f0f0f0",
    borderRadius: 5,
  },
  deviceColumn: {
    flex: 1,
    marginRight: 10,
    alignItems: "flex-start",
  },
  label: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 2,
    color: "#333",
  },
  value: {
    fontSize: 8,
    fontWeight: "normal",
    color: "#555",
  },
  noData: {
    fontSize: 10,
    fontWeight: "normal",
    color: "#555",
    textAlign: "center",
  },
  techNoteContainer: {
    width: "75%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  teknisyenImzaContainer: {
    width: "50%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  notOnemliContainer: {
    width: "65%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  notContainer: {
    width: "35%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  musteriImzaContainer: {
    width: "50%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  techNoteTitle: {
    fontSize: 10,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#333",
  },
  techNoteInput: {
    height: 60,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  imzaInput: {
    height: 50,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  notInput: {
    justifyContent: "center",
    alignItems: "center",
    padding: 4,
    minHeight: 35,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  billingInfo: {
    width: "25%",
    marginTop: 5,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  billingTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 5,
    color: "#333",
  },
  billingText: {
    fontSize: 8,
    color: "#555",
  },
  tableContainer: {
    marginTop: 8,
    padding: 10,
    borderWidth: 1,
    borderColor: "#ddd",
    borderRadius: 5,
  },
  tableTitle: {
    fontSize: 12,
    fontWeight: "bold",
    marginBottom: 10,
    textAlign: "center",
    color: "#333",
  },
  tableRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: 1,
    borderBottomColor: "#ddd",
    paddingVertical: 5,
  },
  tableHeader: {
    fontSize: 8,
    fontWeight: "bold",
    color: "#333",
  },
  tableCell: {
    flex: 1,
    fontSize: 12,
    color: "#555",
    borderLeft: 1,
    borderRight: 1,
    borderColor: "black",
  },
});
