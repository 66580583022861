import { isDisabled } from "@testing-library/user-event/dist/utils";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IconButton } from "../generalComponents";
import { RiAddLine } from "react-icons/ri";

function CihazSelect({
  options,
  onChange,
  defaultValue,
  isDisabled,
  onClickButton,
  filterOperation = true,
  addOperation,
}) {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleAddButtonClick = (e) => {
    e.preventDefault(); // Form gönderme davranışını durdur
    onClickButton(); // IconButtona tıklandığında çağrılacak işlevi çağır
  };

  const handleCihazChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption); // Seçilen cari değeri üst bileşene iletmek için callback'i çağır
  };

  useEffect(() => {
    //console.log(options);
  }, [defaultValue]);

  // react-select için seçeneklerin formatını hazırlama
  const cihazOptions = options.map((cihaz) => ({
    value: cihaz.FirmaCihazId,
    label: cihaz.CihazAdi,
  }));

  if (defaultValue != undefined) {
    if (addOperation == true) {
      defaultValue = cihazOptions.find((x) => x.value == defaultValue);
    } else {
      //console.log(defaultValue.FirmaCihazId);
      defaultValue = cihazOptions.find(
        (x) => x.value == defaultValue.FirmaCihazId
      );
    }
  }

  return (
    <>
      {addOperation == false ? (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleCihazChange}
            options={cihazOptions}
            placeholder="Cihaz Seçin..."
            defaultValue={defaultValue != undefined && defaultValue}
            isDisabled={isDisabled}
          />
          {!filterOperation && addOperation && (
            <IconButton
              Icon={RiAddLine}
              bgColor={"primary-brand-color"}
              iconColor={"white-color"}
              onPress={handleAddButtonClick}
            />
          )}
        </div>
      ) : (
        <div className="flex flex-row w-full justify-center items-center">
          <Select
            className="basic-single w-full"
            classNamePrefix="select"
            onChange={handleCihazChange}
            options={cihazOptions}
            placeholder="Cihaz Seçin..."
            // defaultValue={
            //   defaultValue != undefined ? defaultValue : selectedOption
            // }
            value={
              defaultValue != undefined && selectedOption != undefined
                ? selectedOption
                : defaultValue != undefined
                ? defaultValue
                : selectedOption
            }
            isDisabled={isDisabled}
          />
          {!filterOperation && (
            <IconButton
              Icon={RiAddLine}
              bgColor={"primary-brand-color"}
              iconColor={"white-color"}
              onPress={handleAddButtonClick}
            />
          )}
        </div>
      )}
    </>
  );
}

export default CihazSelect;
