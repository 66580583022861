import React from "react";
import { FaCheck, FaExpandArrowsAlt, FaTimes } from "react-icons/fa";

function TableItemIslemDurum({ islemDurum, onUpdateClick }) {
  const icon = islemDurum.Durum ? <FaCheck /> : <FaTimes />;
  //const icon = <FaCheck />;

  const handleUpdateClick = (e) => {
    onUpdateClick(e, islemDurum); // Seçilen cari öğesini PopupUpdateCari bileşenine iletiyoruz.
  };

  return (
    <div className="p-4 mb-4 rounded-md flex flex-row items-center justify-between border-2 border-primary-brand-color cursor-pointer">
      <div className="flex flex-row items-center">
        <div className=" bg-primary-brand-color mr-2 p-3  flex justify-center items-center text-white-color rounded-full">
          {icon}
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col">
            <h2 className="text-xl font-serif font-semibold ">
              {islemDurum.IslemAdi}
            </h2>
            <h3 className=" text-base">{`Açıklama: ${islemDurum.IslemAciklama}`}</h3>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={(e) => {
            handleUpdateClick(e);
          }}
          className="  text-primary-brand-color px-2 py-2 border-2 border-primary-brand-color rounded-full text-lg mr-4"
        >
          <FaExpandArrowsAlt />
        </button>
      </div>
    </div>
  );
}

export default TableItemIslemDurum;
