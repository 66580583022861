import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IconButton } from "../generalComponents";
import { RiAddLine } from "react-icons/ri";
import { getModeller } from "services/api/yonetimApi/api";

function ModelSelect({
  options,
  onChange,
  defaultValue,
  isDisabled,
  onClickButton,
  filterOperation = true,
  addOperation,
  selectedMarkaId,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [modellerList, setModellerList] = useState([]);

  useEffect(() => {
    if (selectedMarkaId) {
      setSelectedOption(null);
      getModellerProcess(selectedMarkaId).then((modeller) => {
        setModellerList(modeller);
      });
    } else {
      setSelectedOption(null);
      setModellerList(options);
    }
  }, [selectedMarkaId, options]);

  useEffect(() => {
    if (defaultValue && modellerList.length > 0) {
      const defaultModel = modellerList.find(
        (model) => model.ModelId === defaultValue.ModelId
      );
      if (defaultModel !== undefined) {
        setSelectedOption({
          value: defaultModel.ModelId,
          label: defaultModel.ModelAdi,
        });
      }
    }
  }, [defaultValue, modellerList]);

  const transformToOption = (data) => {
    return data.map((item) => ({
      value: item.ModelId,
      label: item.ModelAdi,
    }));
  };

  const modellerListOptions = transformToOption(modellerList);

  const getModellerProcess = async (markaId) => {
    try {
      const getModellerApi = await getModeller(markaId);
      return getModellerApi.data.data;
    } catch (error) {
      console.log("ModelSelect -> getModellerProcess -> Error: ", error);
    }
  };

  const handleModelChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  const handleAddButtonClick = (e) => {
    e.preventDefault();
    onClickButton();
  };

  return (
    <div className="flex flex-row w-full justify-center items-center">
      <Select
        className="basic-single w-full"
        classNamePrefix="select"
        onChange={handleModelChange}
        options={modellerListOptions}
        placeholder="Model Seçin..."
        value={selectedOption}
        isDisabled={isDisabled}
      />
      {!filterOperation && addOperation && (
        <IconButton
          Icon={RiAddLine}
          bgColor={"primary-brand-color"}
          iconColor={"white-color"}
          onPress={handleAddButtonClick}
        />
      )}
    </div>
  );
}

export default ModelSelect;
