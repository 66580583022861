import React, { useState, useEffect } from "react";
import { Header } from "./ui/generalComponents";
import AddServisPopup from "./ui/serviceComponents/AddServisPopup";
import AddPopupServis from "./ui/userServiceComponents/AddPopupServis";
import ServisListItem from "./ui/serviceComponents/ServisListItem";
import { getServisler } from "services/api/servisApi/api";
import UpdateServisPopup from "./ui/serviceComponents/UpdateServisPopup";
import PopupFilterServis from "./ui/serviceComponents/PopupFilterServis";
import PdfPopup from "./ui/serviceComponents/PdfPopup";

function UserServisPage() {
  const [showPopup, setShowPopup] = useState(false);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [servisList, setServisList] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(null);
  const [currentServis, setCurrentServis] = useState(null);
  const [pdfCurrentServis, setPdfCurrentServis] = useState(null);
  const [pdfPopup, setPdfPopup] = useState(false);
  const [filters, setFilters] = useState({
    durum: null,
    cariId: null,
    firmaCihazId: null,
    markaId: null,
    modelId: null,
    islemDurum: null,
  });
  const [refreshTrigger, setRefreshTrigger] = useState(false);

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const toggleUpdatePopup = () => {
    setShowUpdatePopup(!showUpdatePopup);
  };

  const toggleFilterPopup = () => {
    setShowFilterPopup(!showFilterPopup);
  };

  const handleUpdateClick = (servis) => {
    setCurrentServis(servis);
    toggleUpdatePopup();
  };

  const handleUpdateSuccess = () => {
    setRefreshTrigger((prev) => !prev);
  };

  useEffect(() => {
    getServislerProcess();
  }, [pageNumber, showPopup, showUpdatePopup, filters, refreshTrigger]);

  const plusPageProcess = async () => {
    if (pageNumber < totalPageNumber) {
      setPageNumber(pageNumber + 1);
    }
  };

  const minusPageProcess = async () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const handleFilter = (filterValues) => {
    setFilters(filterValues);
    toggleFilterPopup();
    console.log(filterValues);
  };

  const getServislerProcess = async () => {
    try {
      const { durum, cariId, firmaCihazId, markaId, modelId, islemDurum } =
        filters;
      const getServislerApi = await getServisler(
        durum,
        pageNumber,
        20,
        cariId,
        firmaCihazId,
        markaId,
        modelId,
        islemDurum
      );
      if (getServislerApi.success) {
        setServisList(getServislerApi.data.data);
        //console.log(getServislerApi.data.TotalPage);
        setTotalPageNumber(getServislerApi.data.TotalPage);
      } else {
        console.log(
          "ServiceSection -> getServislerProcess -> Servisler Cekilirken Hata: ",
          getServislerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("ServiceSection -> getServislerProcess -> Error:", error);
    }
  };

  const togglePdfPopup = () => {
    setPdfPopup(!pdfPopup);
  };

  const handlePdfClick = (servis) => {
    setPdfCurrentServis(servis);
    togglePdfPopup();
  };

  return (
    <div className="w-full h-full px-2 overflow-auto">
      <Header
        onAddClick={togglePopup}
        title={"Servisler Listesi"}
        pageNumber={pageNumber}
        totalPageNumber={totalPageNumber}
        plusPage={plusPageProcess}
        minusPage={minusPageProcess}
        onFilterClick={toggleFilterPopup}
        isFilter={false}
        addTitle={"Servis Ekle"}
      />

      {servisList.map((servis) => (
        <ServisListItem
          servis={servis}
          handleUpdatePopup={handleUpdateClick}
          isUpdate={false}
          onPdfClick={handlePdfClick}
        />
      ))}

      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <AddPopupServis onClose={togglePopup} />
        </div>
      )}

      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <UpdateServisPopup
            onClose={toggleUpdatePopup}
            currentServis={currentServis}
            onUpdate={handleUpdateSuccess}
          />
        </div>
      )}

      {showFilterPopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <PopupFilterServis
            onClose={toggleFilterPopup}
            onFilter={handleFilter}
          />
        </div>
      )}

      {pdfPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <PdfPopup onClose={togglePdfPopup} servis={pdfCurrentServis} />
        </div>
      )}
    </div>
  );
}

export default UserServisPage;
