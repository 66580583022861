import { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ConfirmModal } from "./ui/generalComponents";

const UserPage = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal durumu için state
  const location = useLocation();
  const currentUrl = location.pathname;
  const Menus = [
    { title: "Servislerim", src: "Chart_fill", path: "/user/servislerim" },
    // { title: "Randevular", src: "Chat", path: "/admin/randevular" },
    //{ title: "Cari", src: "User", path: "/admin/cari" },
    //{ title: "Raporlar", src: "Calendar", path: "/admin/raporlar" },
    { title: "Cihazlarım", src: "Search", path: "/user/cihazlarim" },
    //{ title: "Yönetim", src: "Chart", path: "/admin/yonetim" },
    //{ title: "Kullanıcılar", src: "Folder", path: "/admin/kullanicilar" },
    //{ title: "Ayarlar", src: "Setting", path: "/admin/ayarlar" }, // Ayarlar menüsü
  ];

  useEffect(() => {
    //console.log(currentUrl);
  }, [currentUrl]);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalButton = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="flex">
      <div
        className={`${
          open ? "w-72" : "w-20"
        } bg-primary-brand-color h-auto p-5 pt-8 relative duration-300`}
      >
        <img
          src="../assets/test/control.png"
          alt="controlImage"
          className={`absolute cursor-pointer -right-3 top-9 w-7 border-dark-purple border-2 rounded-full ${
            !open && "rotate-180"
          }`}
          onClick={() => setOpen(!open)}
        />
        <div className="flex gap-x-4 items-center">
          <img
            src="../assets/test/logo.png"
            alt="logoImage"
            className={`cursor-pointer duration-500 ${
              open && "rotate-[360deg]"
            }`}
          />
          <h1
            className={`text-white-color origin-left font-medium text-xl duration-200 ${
              !open && "scale-0"
            }`}
          >
            Bilar Canon Servis
          </h1>
        </div>
        <ul className="pt-6">
          {Menus.map((Menu, index) => (
            <Link to={Menu.path} key={index}>
              <li
                className={`flex rounded-md p-2 cursor-pointer hover:bg-light-white text-white-color text-sm items-center gap-x-4 ${
                  Menu.gap ? "mt-9" : "mt-2"
                } ${currentUrl === Menu.path ? "bg-light-white" : ""}`}
              >
                <img src={`../assets/test/${Menu.src}.png`} alt="menuImage" />
                <span
                  className={`${!open && "hidden"} origin-left duration-200`}
                >
                  {Menu.title}
                </span>
              </li>
            </Link>
          ))}
          <li
            className="flex rounded-md p-2 cursor-pointer hover:bg-light-white text-white-color text-sm items-center gap-x-4 mt-2"
            onClick={handleModalOpen}
          >
            <img src="../assets/test/Setting.png" alt="settingsImage" />
            <span className={`${!open && "hidden"} origin-left duration-200`}>
              Çıkış Yap
            </span>
          </li>
        </ul>
      </div>
      <div className="h-screen flex-1 p-2">
        <div className="w-full h-full">
          <Outlet />
        </div>
      </div>
      {/* <Modal isOpen={isModalOpen} onClose={handleModalClose}>
        <h2>Ayarlar</h2>
        <p>Buraya ayarlarla ilgili içerik gelecek...</p>
      </Modal> */}
      {isModalOpen && (
        <ConfirmModal
          message={"Çıkış Yapmak İstediğinizden Emin misinz ?"}
          onCancel={handleModalClose}
          onConfirm={handleModalButton}
          possButtonText="Çıkış Yap"
        />
      )}
    </div>
  );
};

export default UserPage;
