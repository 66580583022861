import React, { useEffect, useState } from "react";
import Select from "react-select";
import { IconButton } from "../generalComponents";
import { RiAddLine } from "react-icons/ri";
import { getSube } from "services/api/cariApi/api";

function SubeSelect({
  options,
  onChange,
  defaultValue,
  onClickButton,
  addOperation,
  selectedCariId,
  addButtonOperation = true,
}) {
  const [selectedOption, setSelectedOption] = useState(defaultValue);
  const [subelerList, setSubelerList] = useState([]);

  useEffect(() => {
    console.log(defaultValue);
    if (selectedCariId) {
      setSelectedOption(null);
      getSubelerProcess(selectedCariId).then((subeler) => {
        setSubelerList(subeler);
      });
    } else {
      //console.log("Add Yeri");
      setSelectedOption(null);
      setSubelerList(options);
    }
  }, [selectedCariId, defaultValue, options]);

  useEffect(() => {
    console.log("----->");
    if (defaultValue && subelerList.length > 0) {
      const defaultSube = subelerList.find(
        (sube) => sube.SubeId === defaultValue.SubeId
      );

      if (defaultSube != undefined) {
        console.log("----->", defaultSube);
        setSelectedOption({
          value: defaultSube.SubeId,
          label: defaultSube.SubeAdi,
        });
      }
    }
  }, [defaultValue, subelerList]);

  const transformToOption = (data) => {
    return data.map((item) => ({
      value: item.SubeId,
      label: item.SubeAdi,
    }));
  };

  const subeOptions = transformToOption(options);
  const subelerListOptions = transformToOption(subelerList);

  // react-select'in onChange olayı
  const handleSubeChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    onChange(selectedOption); // Seçilen cari değeri üst bileşene iletmek için callback'i çağır
  };

  // useEffect(() => {
  //   //console.log(options);
  // }, [options, defaultValue]);

  // if (defaultValue != undefined) {
  //   defaultValue = subeOptions.find((x) => x.value == defaultValue.SubeId);
  // }

  const handleAddButtonClick = (e) => {
    e.preventDefault(); // Form gönderme davranışını durdur
    onClickButton(); // IconButtona tıklandığında çağrılacak işlevi çağır
  };

  const getSubelerProcess = async (cariId) => {
    try {
      const getSubelerApi = await getSube(cariId);
      if (getSubelerApi.success) {
        console.log(
          "AddServisPopup -> getSubelerProcess -> Subeler Başarılı Şekilde Çekildi ",
          getSubelerApi.basicAciklama
        );
        return getSubelerApi.data;
      } else {
        console.log(
          "AddServisPopup -> getSubelerProcess -> Subeler Cekilirken Hata: ",
          getSubelerApi.basicAciklama
        );
      }
    } catch (error) {
      console.log("AddServisPopup -> getSubelerProcess -> Error:", error);
    }
  };

  return (
    <div className="flex flex-row w-full justify-center items-center">
      <Select
        className="basic-single w-full"
        classNamePrefix="select"
        value={selectedOption}
        onChange={handleSubeChange}
        options={addOperation ? subeOptions : subelerListOptions}
        placeholder={addOperation ? "Şube Seçilen..." : "Şube Seçin..."}
      />
      {addButtonOperation == true && (
        <IconButton
          Icon={RiAddLine}
          bgColor={"primary-brand-color"}
          iconColor={"white-color"}
          onPress={handleAddButtonClick}
        />
      )}
    </div>
  );
}

export default SubeSelect;
