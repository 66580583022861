import { TabPanel, Tab } from "./TabPanel";
import AddMarkaView from "./AddMarkaView";
import AddMarkaPopup from "./AddMarkaPopup";
import AddModelView from "./AddModelView";
import AddParcaView from "./AddParcaView";
import AddCihazTipiView from "./AddCihazTipiView";
import AddIslemDurumView from "./AddIslemDurumView";

export {
  TabPanel,
  AddMarkaView,
  Tab,
  AddMarkaPopup,
  AddModelView,
  AddParcaView,
  AddCihazTipiView,
  AddIslemDurumView,
};
