import React from "react";
import { IoMdAdd } from "react-icons/io";
import { IoFilterSharp } from "react-icons/io5";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { FaDownload, FaSearch } from "react-icons/fa";
import { RiPrinterFill } from "react-icons/ri";

function Header({
  onAddClick,
  title,
  plusPage,
  minusPage,
  pageNumber,
  totalPageNumber,
  onFilterClick,
  isFilter,
  isAdd = true,
  addTitle,
  isRapor = false,
  onRaporClick,
}) {
  return (
    <div className="w-full flex flex-col bg-white-color p-1 border-b-2 border-primary-brand-color mb-2">
      <h1 className="text-primary-brand-color text-2xl font-semibold font-serif mb-2">
        {title}
      </h1>
      <div className="flex flex-row justify-between items-center py-2">
        <div className="flex items-center">
          {isFilter && (
            <button
              onClick={onFilterClick}
              className="bg-secondary-brand-color text-white-color px-3 py-2 border-2 border-primary-brand-color rounded-full text-lg mr-2 mb-2"
            >
              <FaSearch />
            </button>
          )}

          <button
            onClick={minusPage}
            className="bg-secondary-brand-color text-white-color px-3 py-2 border-2 border-primary-brand-color rounded-full text-lg mr-2 mb-2"
          >
            <AiOutlineArrowLeft />
          </button>

          <div className="font-mono font-semibold text-lg mr-2 mb-2">
            {`${pageNumber}/${totalPageNumber}`}
          </div>

          <button
            onClick={plusPage}
            className="bg-secondary-brand-color text-white-color px-3 py-2 border-2 border-primary-brand-color rounded-full text-lg mb-2"
          >
            <AiOutlineArrowRight />
          </button>
        </div>

        <div className="flex  flex-row">
          {isRapor && (
            <button
              onClick={onRaporClick}
              className="bg-secondary-brand-color flex flex-row justify-center items-center text-white-color px-3 py-2 border-2 border-primary-brand-color rounded-full text-lg sm:ml-4 mb-2"
            >
              <p className=" text-lg mr-2">{"Rapor Al"}</p>
              <FaDownload size={16} />
            </button>
          )}
          {isAdd && (
            <button
              onClick={onAddClick}
              className="bg-secondary-brand-color flex flex-row justify-center items-center text-white-color px-3 py-2 border-2 border-primary-brand-color rounded-full text-lg sm:ml-4 mb-2"
            >
              <p className=" text-lg">{addTitle}</p>
              <IoMdAdd size={22} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
