import React from "react";

const SearchBox = ({ searchTerm, onSearch, clearFilter, placeholder }) => {
  const handleInputChange = (event) => {
    onSearch(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch(event.target.value);
    }
  };

  return (
    <div className="flex items-center border-b border-primary-brand-color py-2">
      <input
        type="text"
        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
        placeholder={placeholder}
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <button
        className="flex-shrink-0 bg-primary-brand-color hover:bg-primary-brand-dark-color border-primary-brand-color hover:border-primary-brand-dark-color text-sm border-4 text-white-color py-1 px-2 rounded"
        type="button"
        onClick={() => onSearch(searchTerm)}
      >
        Ara
      </button>
      <button
        className="flex-shrink-0 bg-primary-brand-color hover:bg-primary-brand-dark-color border-primary-brand-color hover:border-primary-brand-dark-color text-sm border-4 text-white-color py-1 px-2 rounded ml-2"
        type="button"
        onClick={clearFilter}
      >
        Filtreleri Temizle
      </button>
    </div>
  );
};

export default SearchBox;
