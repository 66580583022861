import axios from "axios";
import { refreshTokenUser } from "./authApi/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Axios istemcisi oluşturma
const axiosApiInstance = axios.create();

// Bir kilit durumu belirlemek için bir değişken
let isRefreshing = false;
// Bekleyen tüm istekleri tutmak için bir dizi
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// İstek kesici (Request interceptor)
axiosApiInstance.interceptors.request.use(
  async config => {
    let token = localStorage.getItem("userToken");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// Yanıt kesici (Response interceptor)
axiosApiInstance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response.status === 403) {
      console.log("403 yedin kardeş");
      toast.error("Bu İşlemi Gerçekleştirmek İçin Yetkiye Sahip Değilsiniz");
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then(token => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            return axiosApiInstance(originalRequest);
          })
          .catch(err => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise(async (resolve, reject) => {
        try {
          const refreshToken = localStorage.getItem("refreshToken");
          if (!refreshToken) {
            throw new Error("Refresh Token not Found");
          }

          const response = await refreshTokenUser(refreshToken);
          if (response.status === 1) return;
          console.log(response);
          const newAccessToken = response.token;

          localStorage.setItem("userToken", newAccessToken);
          axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
          originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
          processQueue(null, newAccessToken);
          resolve(axiosApiInstance(originalRequest));
        } catch (err) {
          processQueue(err, null);
          reject(err);
          localStorage.clear();
          window.location.href = "/";
        } finally {
          isRefreshing = false;
        }
      });
    }

    return Promise.reject(error);
  }
);

export default axiosApiInstance;
