import React, { useEffect, useState } from "react";
import { Header } from "../generalComponents";
import AddIslemDurumPopup from "./AddIslemDurumPopup";
import { getIslemDurumlari } from "services/api/yonetimApi/api";
import TableItemIslemDurum from "./TabItemIslemDurum";
import UpdateIslemDurumPopup from "./UpdateIslemDurumPopup";

function AddIslemDurumView() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });
  const [islemDurumList, setIslemDurumList] = useState([]);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [currentIslemDurum, setCurrentIslemDurum] = useState(null);

  useEffect(() => {
    getIslemDurumlariProcess();
  }, []);

  const handleAddClick = (e) => {
    // Butonun konumunu alın
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.left, y: buttonRect.bottom });

    // Popup'u açın
    setIsPopupOpen(true);
  };

  const handleClose = () => {
    // Popup'u kapatın
    setIsPopupOpen(false);
  };

  const handleUpdateClose = () => {
    setShowUpdatePopup(false);
  };

  const toggleUpdatePopup = (e, islemDurum) => {
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.right, y: buttonRect.top });

    setCurrentIslemDurum(islemDurum); // Seçilen cari bilgisini state'e kaydediyoruz
    setShowUpdatePopup(!showUpdatePopup);
  };

  const getIslemDurumlariProcess = async () => {
    try {
      const getIslemDurumlariApi = await getIslemDurumlari();
      if (getIslemDurumlariApi.succes) {
        console.log(
          "AddIslemDurumView -> getIslemDurumlariProcess -> İslem Durumları Başarılı Cekildi.",
          getIslemDurumlariApi.basicAciklama
        );
        setIslemDurumList(getIslemDurumlariApi.data);
      } else {
        console.log(
          "AddIslemDurumView -> getIslemDurumlariProcess -> İslem Durumları cekilirken Hata: ",
          getIslemDurumlariApi.basicAciklama
        );
      }
    } catch (error) {
      console.log(
        "AddIslemDurumView -> getIslemDurumlariProcess -> Error: ",
        error
      );
    }
  };

  return (
    <div className="w-full h-full">
      <Header
        title={"İşlem Durum Listesi"}
        onAddClick={handleAddClick}
        addTitle={"İşlem Durum Ekle"}
      />
      <AddIslemDurumPopup
        isOpen={isPopupOpen}
        onClose={handleClose}
        buttonPosition={buttonPosition}
      />
      <div className="mt-4">
        {islemDurumList.map((islemDurum) => (
          <TableItemIslemDurum
            key={islemDurum.IslemDurumId}
            islemDurum={islemDurum}
            onUpdateClick={(e, islemDurum) => {
              toggleUpdatePopup(e, islemDurum);
            }}
          />
        ))}
      </div>
      {showUpdatePopup && (
        <div className="fixed inset-0 bg-black-color bg-opacity-50 flex justify-center items-center">
          <UpdateIslemDurumPopup
            isOpen={showUpdatePopup}
            onClose={handleUpdateClose}
            currentIslemDurum={currentIslemDurum}
            buttonPosition={buttonPosition}
          />
        </div>
      )}
    </div>
  );
}

export default AddIslemDurumView;
