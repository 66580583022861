import React, { useState, useEffect } from "react";
import { getCihaz } from "services/api/cihazApi/api";
import CihazSelect from "./CihazSelect";
import { GetParcalar } from "services/api/yonetimApi/api";
import ParcaSelect from "./ParcaSelect";
import { useFormik } from "formik";
import { addServisCihazSchema } from "services/helpers/validations";
import PopupAddCihaz from "../deviceComponents/PopupAddCihaz";
import AddParcaPopup from "./AddParcaPopup";

function AddCihazPopup({ onConfirm, onCancel, cariId, cariNesne, cihazId }) {
  const [cihazList, setCihazList] = useState([]);
  const [parcalarList, setParcalarList] = useState([]);
  const [selectedCihazId, setSelectedCihazId] = useState("");
  const [selectedParcaId, setSelectedParcaId] = useState("");
  const [cihazIslemList, setCihazIslemList] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [selectedCihazLabel, setSelectedCihazLabel] = useState("");
  const [addCihazPopupShow, setAddCihazPopupShow] = useState(false);
  const [addParcaPopupShow, setAddParcaPopupShow] = useState(false);
  const [buttonPosition, setButtonPosition] = useState({ x: 0, y: 0 });

  const { values, errors, touched, handleSubmit, handleChange, handleBlur } =
    useFormik({
      initialValues: {
        YapilanIslem: "",
        Aciklama: "",
        MusteriAciklamasi: "",
      },
      onSubmit: async (values, bag) => {
        bag.resetForm();
        const YapilanIslemSubmit = values.YapilanIslem;
        const AciklamaSubmit = values.Aciklama;
        const MusteriAciklamaSubmit = values.MusteriAciklamasi;

        const formattedNesne = addCihazFormattedData(
          YapilanIslemSubmit,
          AciklamaSubmit,
          MusteriAciklamaSubmit,
          selectedCihazId,
          selectedParcaId
        );
        formattedNesne != null &&
          setCihazIslemList([...cihazIslemList, formattedNesne]);
      },
      validationSchema: addServisCihazSchema,
    });

  useEffect(() => {
    getCihazlarProcess();
    getParcalarProcess();
    console.log(cihazId);
    if (cihazId !== null) {
      console.log("Deneme");
      setSelectedCihazId(cihazId);
    } else {
      console.log("deneme 2");
      setSelectedCihazId("");
    }
  }, [addCihazPopupShow, addParcaPopupShow]);

  useEffect(() => {
    if (Object.keys(touched).length > 0 && Object.keys(errors).length === 0) {
      // if (selectedSehirId && selectedIlceId && selectedMusteriTip) {
      setIsFormSubmitted(true);
      // }
    } else {
      setIsFormSubmitted(false);
    }
  }, [touched, errors]);

  const handleAddCihaz = () => {
    setAddCihazPopupShow(true);
  };

  const handleAddParca = (e) => {
    const buttonRect = e.target.getBoundingClientRect();
    setButtonPosition({ x: buttonRect.left, y: buttonRect.bottom });
    setAddParcaPopupShow(true);
  };

  const handleCihazChange = (cihaz) => {
    setSelectedCihazId(cihaz.value);
    setSelectedCihazLabel(cihaz.label);
  };

  const handleParcaChange = (parca) => {
    setSelectedParcaId(parca.value);
  };

  const getCihazlarProcess = async () => {
    try {
      const getCihazlarApi = await getCihaz(
        true,
        1,
        1000,
        null,
        cariId,
        null,
        null,
        null
      );
      //console.log(getCihazlarApi.data);
      setCihazList(getCihazlarApi.data.data);
    } catch (error) {
      console.log("AddCihazPopup -> getCihazlarProcess -> Error: ", error);
    }
  };

  const getParcalarProcess = async () => {
    try {
      const getParcalarApi = await GetParcalar(1, 1000, null);
      setParcalarList(getParcalarApi.data.data);
    } catch (err) {
      console.log("AddCihazPopup -> getParcalarProcess -> Error: ", err);
    }
  };

  const addCihazFormattedData = (
    yapilanIslemSubmit,
    aciklamaSubmit,
    musteriAciklamaSubmit,
    selectedCihazId,
    selectedParcaId
  ) => {
    try {
      if (cihazIslemList.length == 0) {
        setIsDisabled(true);
        const formattedData = {
          FirmaCihazId: selectedCihazId,
          FirmaCihazAdi: selectedCihazLabel,
          ServisIslemleri: [
            {
              ParcaId: selectedParcaId,
              YapilanIslem: yapilanIslemSubmit,
              Aciklama: aciklamaSubmit,
              MusteriAciklamasi: musteriAciklamaSubmit,
            },
          ],
        };
        return formattedData;
      } else {
        cihazIslemList
          .find((x) => x.FirmaCihazId == selectedCihazId)
          .ServisIslemleri.push({
            ParcaId: selectedParcaId,
            YapilanIslem: yapilanIslemSubmit,
            Aciklama: aciklamaSubmit,
            MusteriAciklamasi: musteriAciklamaSubmit,
          });
      }

      return null;
    } catch (error) {
      console.log("AddCihazPopup -> addCihazFormattedData -> Error: ", error);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black-color bg-opacity-50">
      <div className="bg-white-color p-8 rounded-lg w-3/4 h-5/6  h-max-5/6  border-2 border-primary-brand-color overflow-auto">
        <p className="text-2xl font-semibold mb-4">{"Cihaz Ekle"}</p>
        <hr />
        <div className="mb-4 mt-2">
          <label className="block text-sm font-semibold mb-1" htmlFor="cihaz">
            Cihaz
          </label>
          <CihazSelect
            options={cihazList}
            onChange={handleCihazChange}
            isDisabled={isDisabled}
            onClickButton={handleAddCihaz}
            filterOperation={false}
            addOperation={true}
            defaultValue={cihazId}
          />
        </div>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-x-2 mt-4">
            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="MusteriAciklamasi"
              >
                Müşteri Açıklama
              </label>
              <input
                type="text"
                id="MusteriAciklamasi"
                name="MusteriAciklamasi"
                value={values.MusteriAciklamasi}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.MusteriAciklamasi && touched.MusteriAciklamasi && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.MusteriAciklamasi}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="Aciklama"
              >
                Servis Açıklama
              </label>
              <input
                type="text"
                id="Aciklama"
                name="Aciklama"
                value={values.Aciklama}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.Aciklama && touched.Aciklama && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.Aciklama}
                  </p>
                </div>
              )}
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="parca"
              >
                Parça
              </label>
              <ParcaSelect
                options={parcalarList}
                onChange={handleParcaChange}
                onClickButton={handleAddParca}
                filterOperation={false}
              />
            </div>

            <div className="mb-4">
              <label
                className="block text-sm font-semibold mb-1"
                htmlFor="YapilanIslem"
              >
                Yapılacak İşlem
              </label>
              <input
                type="text"
                id="YapilanIslem"
                name="YapilanIslem"
                value={values.YapilanIslem}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-full border-2 border-gray-200 p-1 rounded-md"
              />
              {errors.YapilanIslem && touched.YapilanIslem && (
                <div>
                  <p className="font-semibold text-xs text-red-color">
                    {errors.YapilanIslem}
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-end">
            <button
              onClick={onCancel}
              className="bg-red-color text-white-color px-4 py-2 rounded-md mr-4"
            >
              Vazgeç
            </button>
            <button
              type="submit"
              // onClick={onConfirm}
              className="bg-green-color text-white-color px-4 py-2 rounded-md"
            >
              Cihaza İşlem Ekle
            </button>
          </div>
        </form>
        <div className="mt-4">
          <h3 className="text-lg font-semibold mb-2">Cihaz Servis Bilgileri</h3>
          <p className=" font-bold">
            Müşteri Açıklaması - Yapılan İşlem - Servis Açıklaması
          </p>
          <ul>
            {cihazIslemList.map((nesne, nesneIndex) => (
              <React.Fragment key={nesneIndex + 1}>
                {nesne.ServisIslemleri.map((islem, islemIndex) => (
                  <li className=" list-decimal" key={islemIndex}>
                    {islem.MusteriAciklamasi} - {islem.YapilanIslem} -{" "}
                    {islem.Aciklama}
                  </li>
                ))}
              </React.Fragment>
            ))}
          </ul>
        </div>
        <div className="flex justify-end mt-4">
          <button
            disabled={cihazIslemList.length === 0}
            onClick={() => {
              onConfirm(cihazIslemList);
              onCancel();
            }}
            className={`bg-${
              cihazIslemList.length === 0 ? "third-brand-color" : "red-color"
            } text-white-color px-4 py-2 rounded-md`}
          >
            Devam Et
          </button>
        </div>
        {addCihazPopupShow && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <PopupAddCihaz
              onClose={() => setAddCihazPopupShow(false)}
              cariId={cariId}
              cariNesne={cariNesne}
            />
          </div>
        )}

        <AddParcaPopup
          isOpen={addParcaPopupShow}
          onClose={() => setAddParcaPopupShow(false)}
        />
      </div>
    </div>
  );
}

export default AddCihazPopup;
