import React from "react";
import {
  FaCheck,
  FaExpandArrowsAlt,
  FaTimes,
  FaPencilAlt,
  FaAddressBook,
  FaLocationArrow,
} from "react-icons/fa";
import { IoFilterSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

function TableItem({ cari, onUpdateClick }) {
  const navigate = useNavigate();
  const icon = cari.Durum ? (
    <FaCheck className="text-green-color" />
  ) : (
    <FaTimes className="text-red-color" />
  );
  //const icon = <FaCheck />;

  const handleUpdateClick = () => {
    onUpdateClick(cari); // Seçilen cari öğesini PopupUpdateCari bileşenine iletiyoruz.
  };

  const handleItemClick = () => {
    navigate("/admin/cari/subeler", { state: { cari } });
  };

  return (
    <div className="p-4 mb-4 rounded-md flex flex-row items-center justify-between border-2 border-primary-brand-color cursor-pointer">
      <div className="flex flex-row items-center" onClick={handleItemClick}>
        <div className="border-2 border:primary-brand-color mr-2 p-3  flex justify-center items-center  rounded-full">
          {icon}
        </div>
        <div className="flex flex-row items-center">
          <div className="flex flex-col">
            <h2 className="text-xl font-serif font-semibold ">
              {cari.CariUnvan}
            </h2>
            <h3 className=" text-base">{`Müşteri Tip: ${cari.CariTipi}`}</h3>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={handleUpdateClick}
          className="  text-primary-brand-color px-2 py-2 border-2 border-primary-brand-color rounded-full text-lg mr-4"
        >
          <FaPencilAlt />
        </button>

        <button
          onClick={handleItemClick}
          className="  text-primary-brand-color px-2 py-2 border-2 border-primary-brand-color rounded-full text-lg mr-4"
        >
          <FaLocationArrow />
        </button>
      </div>
    </div>
  );
}

export default TableItem;
