import axios from "axios";
import {
  ACTIVE_SERVIS_CIHAZ,
  ADD_SERVIS,
  DELETE_SERVIS,
  DELETE_SERVIS_BELGE,
  DELETE_SERVIS_CIHAZ,
  DOWNLOAD_SERVIS_BELGELER,
  GET_SERVISLER,
  GET_SERVISLER_BY_ID,
  UPDATE_CIHAZ_ISLEM,
  UPDATE_SERVIS,
  USER_ADD_SERVIS,
} from "services/helpers/contants";
import axiosApiInstance from "../axiosClient";

export const addServis = async (
  CariId,
  SubeId,
  Tarih,
  IslemDurumId,
  TeknisyenId,
  CihazlarList,
  SelectedBelgeler,
  SmsBilgi
) => {
  console.log(CariId, SubeId, Tarih, IslemDurumId, TeknisyenId, CihazlarList);
  console.log({
    CariId: CariId,
    SubeId: SubeId,
    Tarih: Tarih.toJSON(),
    IslemDurumId: IslemDurumId,
    TeknisyenId: TeknisyenId,
    Cihazlar: CihazlarList,
    Belgeler: SelectedBelgeler,
    SmsBilgi: SmsBilgi,
  });
  try {
    const { data } = await axiosApiInstance.postForm(`${ADD_SERVIS}`, {
      CariId: CariId,
      SubeId: SubeId,
      Tarih: Tarih.toJSON(),
      IslemDurum: IslemDurumId,
      TeknisyenId: TeknisyenId,
      Cihazlar: CihazlarList,
      Belgeler: SelectedBelgeler,
      SmsBilgi: SmsBilgi,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "cihazApi -> api -> updateCihaz -> Cihaz Güncellenirken Hata"
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("ServisApi -> api -> addServis -> Error: ", err);
  }
};

export const userAddServis = async (
  CariId,
  SubeId,
  Tarih,
  IslemDurumId,
  CihazlarList,
  SelectedBelgeler
) => {
  console.log(CariId, SubeId, Tarih, IslemDurumId, CihazlarList);
  console.log({
    CariId: CariId,
    SubeId: SubeId,
    Tarih: Tarih.toJSON(),
    IslemDurumId: IslemDurumId,
    Cihazlar: CihazlarList,
    Belgeler: SelectedBelgeler,
  });
  try {
    const { data } = await axiosApiInstance.postForm(`${USER_ADD_SERVIS}`, {
      CariId: CariId,
      SubeId: SubeId,
      Tarih: Tarih.toJSON(),
      IslemDurum: IslemDurumId,
      Cihazlar: CihazlarList,
      Belgeler: SelectedBelgeler,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "cihazApi -> api -> updateCihaz -> Cihaz Güncellenirken Hata"
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("ServisApi -> api -> addServis -> Error: ", err);
  }
};

export const updateServis = async (
  ServisId,
  CariId,
  SubeId,
  Tarih,
  IslemDurumId,
  TeknisyenId,
  CihazlarList,
  SelectedBelgeler
) => {
  console.log(CariId, SubeId, Tarih, IslemDurumId, TeknisyenId, CihazlarList);
  console.log({
    CariId: CariId,
    SubeId: SubeId,
    Tarih: Tarih.toJSON(),
    IslemDurumId: IslemDurumId,
    TeknisyenId: TeknisyenId,
    Cihazlar: CihazlarList,
    Belgeler: SelectedBelgeler,
  });
  try {
    const { data } = await axiosApiInstance.postForm(`${UPDATE_SERVIS}`, {
      Durum: true,
      ServisId: ServisId,
      CariId: CariId,
      SubeId: SubeId,
      Tarih: Tarih.toJSON(),
      IslemDurum: IslemDurumId,
      TeknisyenId: TeknisyenId,
      Cihazlar: CihazlarList,
      Belgeler: SelectedBelgeler,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "cihazApi -> api -> updateServis -> Servis Güncellenirken Hata"
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("ServisApi -> api -> UpdateServis -> Error: ", err);
  }
};

export const deleteServisCihaz = async (servisId, servisCihazId) => {
  console.log(servisId, servisCihazId);
  try {
    const { data } = await axiosApiInstance.post(
      `${DELETE_SERVIS_CIHAZ}?servisId=${servisId}&servisCihazId=${servisCihazId}`
    );
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "ServisApi -> api -> deleteServisCihaz ->Servis Cihaz Silinirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (error) {
    console.log("ServisApi -> api -> deleteServisCihaz -> Error: ", error);
  }
};

export const aktifServisCihaz = async (servisId, servisCihazId) => {
  console.log(servisId, servisCihazId);
  try {
    const { data } = await axiosApiInstance.post(
      `${ACTIVE_SERVIS_CIHAZ}?servisId=${servisId}&servisCihazId=${servisCihazId}`
    );
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "ServisApi -> api -> aktifServisCihaz ->Servis Cihaz Aktifleştirilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (error) {
    console.log("ServisApi -> api -> aktifServisCihaz -> Error: ", error);
  }
};

export const getServisById = async (servisId) => {
  try {
    const { data } = await axiosApiInstance.get(
      `${GET_SERVISLER_BY_ID}?servisId=${servisId}`
    );
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data.data,
      };
    } else {
      console.log(
        "ServisApi -> api -> aktifServisCihaz ->Servis Cihaz Aktifleştirilirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("ServisApi -> api -> getServisById -> Error: ", err);
  }
};

export const updateServisCihazIslem = async (
  servisCihazIslemId,
  servisCihazId,
  parcaId,
  yapilanIslem,
  aciklama,
  musteriAciklamasi
) => {
  try {
    console.log("Parca ıD: ", parcaId);
    const { data } = await axiosApiInstance.post(`${UPDATE_CIHAZ_ISLEM}`, {
      Durum: true,
      ServisCihazIslemId: servisCihazIslemId,
      ServisCihazId: servisCihazId,
      ParcaId: parcaId == "" ? null : parcaId,
      YapilanIslem: yapilanIslem,
      Aciklama: aciklama,
      MusteriAciklamasi: musteriAciklamasi,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
        data: data.data,
      };
    } else {
      console.log(
        "ServisApi -> api -> updateServisCihazIslem ->Servis Cihaz Güncellenirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("ServisApi -> api -> updateServisCihazIslem -> Error: ", err);
  }
};

export const deleteServis = async (servisId) => {
  console.log("----->", servisId);
  try {
    const { data } = await axiosApiInstance.post(
      `${DELETE_SERVIS}?servisid=${servisId}`
    );
    if (data.BasicStatus === "Basarili") {
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "ServisApi -> api -> deleteServis ->Servis Silinirken Hata: ",
        data.BasicAciklama
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("ServisApi -> api -> deleteServis -> Error: ", err);
  }
};

export const getServisler = async (
  Durum,
  PageNumber,
  PageSize,
  CariId,
  FirmaCihazId,
  MarkaId,
  ModelId,
  ServisDurumId,
  BaslangicTarih,
  BitisTarih
) => {
  try {
    console.log("Servis Durum Id: ", ServisDurumId);
    const { data } = await axiosApiInstance.post(`${GET_SERVISLER}`, {
      Durum: Durum,
      PageNumber: PageNumber,
      PageSize: PageSize,
      CariId: CariId,
      FirmaCihazId: FirmaCihazId,
      MarkaId: MarkaId,
      ModelId: ModelId,
      IslemDurum: ServisDurumId,
      BaslangicTarihi: BaslangicTarih,
      BitisTarihi: BitisTarih,
    });
    if (data.BasicStatus === "Basarili") {
      return {
        success: true,
        basicAciklama: data.BasicAciklama,
        data: data,
      };
    } else {
      console.log(
        "cihazApi -> api -> updateCihaz -> Cihaz Güncellenirken Hata"
      );
      return {
        success: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("ServisApi -> api -> getServisler -> Error: ", err);
  }
};

export const deleteServisBelge = async (belgeId) => {
  try {
    const { data } = await axiosApiInstance.post(
      `${DELETE_SERVIS_BELGE}?belgeId=${belgeId}`
    );
    if (data.BasicStatus === "Basarili") {
      console.log(
        "ServisApi -> api -> deleteServisBelge -> Belge Başarılı Şekilde Silindi"
      );
      return {
        succes: true,
        basicAciklama: data.BasicAciklama,
      };
    } else {
      console.log(
        "ServisApi -> api -> deleteServisBelge -> Belge Silinirken Hata"
      );
      return {
        succes: false,
        basicAciklama: data.BasicAciklama,
      };
    }
  } catch (err) {
    console.log("ServisApi -> api -> deleteServisBelge -> Error: ", err);
  }
};

export const downloadServisBelgeler = async (servisId) => {
  try {
    const response = await axiosApiInstance.post(
      `${DOWNLOAD_SERVIS_BELGELER}?servisId=${servisId}`,
      null, // İstek gövdesine ihtiyacınız yoksa null kullanabilirsiniz
      {
        responseType: "blob",
      }
    );

    if (response.status === 200) {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      console.log(response);
      const downloadUrl = window.URL.createObjectURL(blob);
      const disposition = response.headers["content-disposition"];
      let filename = `Canon Servis Dosyaları ${servisId}`;

      if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, "");
        }
      }

      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl); // Bellek sızıntısını önlemek için URL'yi serbest bırakın
    } else {
      console.error("Dosya indirilemedi:", response.statusText);
    }
  } catch (err) {
    console.log("ServisApi -> api -> downloadServisBelgeler -> Error: ", err);
  }
};
